:root {
  --primary-color: #004c75;
  --secondary-color: rgba(0, 76, 117, 0.5); /* 50% opacity */
}

body {
  background-color: #f8f9fa; /* Light gray background */
}

.navbar, .btn-primary {
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}

.btn-primary:hover, .btn-primary:focus {
  background-color: var(--secondary-color) !important;
  border-color: var(--secondary-color) !important;
}

h1, h2, h3, h4, h5, h6, .navbar-brand {
  color: var(--primary-color);
}

.form-control {
  max-width: 100%;
  border-color: var(--primary-color);
}
.form-group {
  margin-bottom: 1rem;
}

.form-control:focus {
  border-color: var(--primary-color);
  box-shadow: 0 0 0 0.2rem var(--secondary-color);
}

.btn-primary {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.card {
  border: none;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}